

/* ------------------------------ */
/* MAIN LAYOUT
/* ------------------------------ */

html, body{ height:100%}
body{ color:#000; font-family:'VolkswagenMed'; font-size:62.5%; -webkit-font-smoothing: antialiased;}
#page{ position:relative; overflow:hidden; min-height:100%;
	.node-type-artwork &{
		height:auto !important;
	}
}
.section-portfolio #page{ min-height:770px}
#header{ padding:20px 0 22px; text-align:center; background:url(../images/line.png) center bottom no-repeat; position:relative;
	&.White{ background-image:url(../images/line_white.png); background-size:100% auto}
}
#main{ position:relative; margin-top:60px; margin-bottom:80px;
	.page-node-33 &{
		margin-top:20px;
	}
}
.width{ margin:0 70px; max-width:1600px; }
#content > .inner{ position:relative;}
#page-content{ position:relative}
#logo{ max-width:94px; display:inline-block}

h1#page-title{ opacity: 0}

.sidebar-first #page-content,
.sidebar-first h1#page-title{ float:left; width:52%}
.sidebar-first #sidebar{ float:right; width:48%}
.sidebar-first #sidebar > .inner{ padding-left:50px}
.view-page-image, .page-circle { max-width:450px; margin:0 auto}

.layout{ @include clearfix;}
.grid{ float:left}
.layout-half{
	.grid{ width:50%;
		&:nth-child(odd){ padding-right:$gutter/2; padding-left:0; clear:left}
		&:nth-child(even){ padding-left:$gutter/2}
	}
}
.layout-third{
	.grid{ width:33.333%;
		&:nth-child(3n-2){ padding-right:($gutter/3)*2}
		&:nth-child(3n-1){ padding-left:($gutter/3); padding-right:($gutter/3)}
		&:nth-child(3n){ padding-left:($gutter/3)*2}
	}
}
.layout-quarter{
	.grid{ width:25%;
		&:nth-child(3n-3){ padding-right:($gutter/3)*2}
		&:nth-child(3n-2),
		&:nth-child(3n-1){ padding-left:($gutter/3); padding-right:($gutter/3)}
		&:nth-child(3n){ padding-left:($gutter/3)*2}
	}
}
.layout-sixth{
	.grid{
		&:nth-child(odd){ width:16.666%; padding-right:$gutter/2}
		&:nth-child(even){ width:83.333; padding-left:$gutter/2}
	}
}


/* ------------------------------ */
/* MENUS
/* ------------------------------ */

#nav, #filter{ width:190px; position:absolute; top:0; right:-190px; background:#fff}
#nav:before, #filter:before{ content:''; display:none; background:transparent url(../images/line_vert.png) repeat-y; position:absolute; left:-3px; top:0; width:5px; height:100%; z-index:-1}
.menu-open #nav:before,
.filter-open #filter:before{ display:block}
#nav > .inner{ padding-top:25px}
#nav ul.menu, #nav ul.menu li{ margin:0; padding:0; list-style:none;}
#nav ul.menu{ font-size:1.5em; margin-bottom:40px}
#nav ul.menu li{ padding-left:30px; font-weight:bold; position:relative; margin-bottom:40px;}
#nav ul.menu li:before{ content:''; position:absolute; left:2px; top:4px; width:21px; height:12px; background:url(../images/arrow_nav.png) 0 0 no-repeat}
#nav ul.menu li a{ text-decoration:none; display:block; margin-bottom:8px}
#nav ul.menu li a:hover{ text-decoration:underline}
#nav ul.menu ul{ font-size:1em; margin:0; font-family:'VolkswagenReg'}
#nav ul.menu ul li{ padding-left:0; margin-bottom:0; background:none; font-weight:normal}
#nav ul.menu ul li:before{ display:none}
#nav .menu-name-main-menu  .menu, #nav .menu-name-main-menu .last {margin-bottom:0; }

#social{ padding-left:30px}
#social .title{ font-family:'Dado'; font-size:1.2em; margin-bottom:15px}
#social ul, ul.social_list {overflow:hidden; display:inline-block; min-height:30px; float:left; }
#social ul, ul.social_list, #social ul li{ margin:0; padding:0; list-style:none}
#social ul li, ul.social_list li{ float:left; padding-bottom:0;}
#social ul li a, ul.social_list li a{ background:url(../images/sprite.png); height:25px; display:block; text-indent:-999em; margin:0 20px 0 0}
#social ul li a.social_twitter, ul.social_list li a.social_twitter { width:28px; background-position:0 -140px}
#social ul li a.social_tumblr, ul.social_list li a.social_tumblr { width:14px; background-position:-40px -140px}
#social ul li a.social_facebook, ul.social_list li a.social_facebook { width:13px; background-position:-120px -140px;}
#social ul li a.social_instagram, ul.social_list li a.social_instagram { width:23px; background-position:-159px -140px}

.icon-link {
  position:relative;
  display:table-cell;
  float:none;
  padding-left:45px;
  padding-top:0;
  margin-right:0;
  margin-bottom:0;
  font-weight:bold;
  text-decoration:none;
  font-size:1.5em;
  font-family:'VolkswagenMed' !important;
  line-height:1.4em;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  vertical-align: middle;
}
.icon-link:hover {
  text-decoration:underline;
}
.icon-link .icon {
  position:absolute;
  top:50%;
  left:0;
  background:transparent url(../images/sprite.png) -132px -93px no-repeat;
  height:30px;
  width:33px;
  margin-top:-15px;
}

.icons-set {display:table; width:100%; margin-top:10px; }
.icons-set .social_list {display:table-cell; width:180px; }

.mailinglist {min-width:170px; }
.mailinglist .icon {background-image:url(../images/sprite.png); }
.emailme {min-width:123px; }
.emailme .icon {
  background-position: -146px -4px;
  height:25px;
  margin-top:-13px;
}

#nav .link_signup {
  width:184px;
  display:block;
  position:relative;
  margin-bottom:30px;
  margin-top:40px;
  margin-left:4px;
  padding:20px 60px 20px 30px;
  background:transparent url(../images/signup/signup_btn_sides.png) 100% 50% repeat-y;
  font-size:1.5em;
  font-family:'VolkswagenBold';
  font-weight:bold;
  text-decoration:none;
  line-height:1.2em
}
#nav .link_signup:hover {
  text-decoration:underline;
}
#nav .link_signup .top {
  width:100%;
  display:block;
  height:6px;
  position:absolute;
  top:0;
  right:0;
  background:transparent url(../images/signup/signup_btn_top.png) 100% 0 no-repeat;
}
#nav .link_signup .bottom {
  width:100%;
  display:block;
  height:10px;
  position:absolute;
  bottom:0;
  right:0;
  background:transparent url(../images/signup/signup_btn_bottom.png) 100% 100% no-repeat;
}


/* TOGGLES
----------------------------------*/

#toggles{ position:relative;
	&.white a{ color:#fff}
}
.toggle{ position:absolute; right:0; }
.toggle a{ font-family:'VolkswagenReg'; font-size:1.5em; text-decoration:none; position:relative; height:24px; display:inline-block;padding-right:40px; z-index:1  }
.toggle a:after{ content:''; background:url(../images/sprite.png); display:block; position:absolute; right:0; top:0;}
.toggle span.cover{ position:absolute; z-index:0; top:0; left:0; background:#fff; width:100%; height:100%; opacity:0}
.toggle span.fade{ opacity:.6; z-index:2}

#menu-toggle{ top:-47px;
	a:after{ width:31px; height:30px; top:-2px }
	a.close-nav:after{ background-position:-31px 0}
	.white &{
		a:after{ background-position:-152px -33px}
		a.close-nav:after{ background-position:-31px 0}
	}
}
#filter-toggle{ top:18px}
#filter-toggle a:after{ background-position:0 -40px; width:33px; height:27px; }
#filter-toggle a.close-filter:after{ background-position:-40px -40px}
#floating-filter-toggle{ position:fixed; right:85px; top:0; opacity:0; background:#fff url(../images/sprite.png) 11px -28px; width:50px; height:50px; border-radius:50px; border:1px solid #000; cursor:pointer; }
.floating-toggle #floating-filter-toggle{ top:15px; opacity:1; z-index:3 }
.menu-open #floating-filter-toggle,
.filter-open #floating-filter-toggle{ display:none}

.section-portfolio .view-portfolio.view-display-id-projects .view-taxonomy-term{ display:none}

#project-info{ text-align:center; max-width:620px; margin:0 auto; opacity:0; display:none}
.project-info #project-info{ opacity:1; display:block}
#project-info #project-info-toggle{ right:auto; left:25px; top:0; display: none !important;}
#project-info #project-info-toggle a{ display:block; padding-right:0}
#project-info #project-info-toggle a:after{ width:12px; height:21px; background-position: -30px -80px; left:-25px; top:2px}
#project-info #project-info-toggle a.close:after{ background-position:0 -80px; width:22px; height:18px; }
#project-info #project-info-toggle span{ display:inline; position:relative; opacity:1}
#project-info #project-info-toggle span.mobile-title{ display:none}
#project-info #project-info-toggle .close span.read-about{ display:none}
#project-info #project-info-description{ opacity:0; display:none}
#project-info.opened #project-info-description{ opacity:1; padding-bottom:50px; }
#project-info h2{ margin:0 0 10px}

#overlay{ position:fixed; top:0; left:0; width:100%; height:100%; opacity:0; z-index:0; background:#fff}
.loading #overlay{ opacity:0.4; z-index:4}

#loading{ position:absolute; width:30px; left:50%; margin:-15px 0 0 -15px; height:30px; background:#fff url(../images/ajax-loader.gif) center center no-repeat; display:none; z-index:999; opacity:0; padding:5px; border-radius:30px;
}
.loading #loading,
.loading-artwork #loading{ opacity:1; display:block;}
.node-type-case-study{
	&.loading,
	&.loading-artwork{
		#loading{
			display:none;
		}
	}
}
.loading-artwork #loading{ left:50%!important;}

#header, #toggles, #main{ z-index:1}
#nav, #filter{ z-index:3}

.menu-open #overlay, .filter-open #overlay{ z-index:2; opacity:0.4}
.menu-open #toggles, .filter-open #toggles, .filter-open #main{ z-index:3}

.menu-open #header, .menu-open #toggles, .menu-open #nav, .menu-open #main, .menu-open #intro,
.filter-open #header, .filter-open #toggles, .filter-open #filter, .filter-open #main,
.menu-open #loading, .filter-open #loading{ transform:translate3d(-190px,0,0); -o-transform: translate3d(-190px,0,0); -ms-transform: translate3d(-190px,0,0); -moz-transform: translate3d(-190px,0,0); -webkit-transform: translate3d(-190px,0,0); }
.menu-open #intro #header{ transform:translate3d(-0,0,0); -o-transform: translate3d(-0,0,0); -ms-transform: translate3d(-0,0,0); -moz-transform: translate3d(-0,0,0); -webkit-transform: translate3d(-0,0,0);}
.filter-open.loading-artwork #loading,
.filter-open.loading #loading{ transform:translate3d(0,0,0); -o-transform: translate3d(0,0,0); -ms-transform: translate3d(0,0,0); -moz-transform: translate3d(0,0,0); -webkit-transform: translate3d(0,0,0); }

.filter-open #page-content{ transform:translate3d(190px,0,0); -o-transform: translate3d(190px,0,0); -ms-transform: translate3d(190px,0,0); -moz-transform: translate3d(190px,0,0); -webkit-transform: translate3d(190px,0,0); margin-right:190px }

.fade{ opacity:0.6}
.fade a{ cursor:default}
.transition{ -webkit-transition: all .2s ease-in-out; -moz-transition: all .2s ease-in-out; -ms-transition: all .2s ease-in-out; -o-transition: all .2s ease-in-out; transition: all .2s ease-in-out;}


/* FILTER
----------------------------------*/

#filter{ padding-top:25px; }
#filter ul, #filter ul li{ margin:0; padding:0; list-style:none;}
#filter ul{ font-family:'VolkswagenReg'}
#filter p, #filter ul{ font-size:1.5em;  }
#filter p.filter-title{ padding-left:30px; font-weight:bold; position:relative}
#filter p.filter-title:before{ content:''; position:absolute; left:2px; top:4px; width:21px; height:12px; background:url(../images/arrow_nav.png) 0 0 no-repeat}
#filter ul.reset{ margin-bottom:15px}
#filter ul.filter{ margin-bottom:40px}
#filter ul li{ padding-left:30px}
#filter ul li a{ text-decoration:none}
#filter ul li a:hover{ text-decoration:underline}
#filter ul li a.fade{ opacity:1; color:#cccccc}
#filter ul li a.fade:hover{ cursor:default; text-decoration:none}
#filter a.active{ font-style:italic; font-weight:bold}

.portfolio-filter,
.portfolio-back{
	padding:0 0 20px; text-align:center; font-size:1.5em; font-family:'VolkswagenReg';
	a{
		display:inline-block; margin:0 45px; position:relative; text-decoration:none;
		&:first-child{ margin-left:0}
		&:last-child{ margin-right:0}
		&.active{
			font-weight:bold; font-family:'VolkswagenMed';
			&:before{
				display:block; content:''; position:absolute; left:-35px; top:4px; width:26px; height:13px; background:url('../images/arrow_right@2x.png'); background-size:26px 13px;
			}
		}
		&:hover{
			text-decoration:underline;
		}

	}
}

.portfolio-back{
	display:none;
	a{
		background:url("../images/arrow_left@2x.png") 0 50% no-repeat; background-size:26px 13px; padding-left:40px;
	}
}


/* ------------------------------ */
/* HTML ELEMENTS
/* ------------------------------ */

h1, h2, h3, h4, h5{ font-weight:normal; line-height:1.25}
h1{ font-size:3em; margin:0 0 25px}
h2{ font-size:2em; margin:40px 0 20px}
h3{ font-size:1.6em}
h4, h5{ font-size:1.5em}
h2 a, h3 a{ text-decoration:none}
h2 a:hover, h3 a:hover{ text-decoration:underline}

h2.line {font-size:3em; background:url(../images/line.png) left bottom no-repeat; padding-bottom:40px; margin-bottom:40px; margin-top:90px; }

p, ul, ol{ line-height:1.5em; font-size:1.5em; margin: 0 0 15px; padding:0;}
ul, ol{ margin-left:40px; padding:0; }
p:last-child, ul:last-child, ol:last-child{ margin-bottom:0}
ul{ list-style:disc; }
ul li, ol li{ padding-left:5px; padding-bottom:5px; }
ul ul,
ol ol{ font-size:1em; margin:10px 0 10px 30px; }
ul ul{ list-style:none;}

a{ color:#000; outline:none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); -webkit-tap-highlight-color: rgba(255, 255, 255, 0);  }
a:active{ outline:none}
a:link{ outline:none}
a:hover{ text-decoration:none}
img.lb-image{ max-width:none}
img{ vertical-align:middle;}

.text-large p {font-size:2em; }


/* DRUPAL OVERRIDES
----------------------------------*/
#admin-menu .dropdown li{ font-size:9px}
div.messages{ font-size:1em}
div.messages p,
div.messages ul{ font-size:1em}
/*.contextual-links-region{ position:static}*/

.node-edit{ position:absolute; top:0; right:0; z-index:20}
ul.tabs-primary{ position:absolute; top:-40px; left:0; margin:0; padding:0; font-size:1em; background:none;}
ul.tabs-primary li{ margin:0; padding:0 1px 0 0; border:none; background:none; text-shadow:none; border-radius:0}
.node-edit,
ul.tabs-primary li a{ border:none; background:#000; color:#fff; text-transform:uppercase; letter-spacing:0; border-radius:0; padding:2px 5px; text-decoration:none }
.node-edit:hover,
ul.tabs-primary li a:hover{ opacity:0.6}

#admin-menu img{ width:16px}


/* ------------------------------ */
/* PAGE ELEMENTS
/* ------------------------------ */

#skip-link { margin: 0;}
#skip-link a,
#skip-link a:visited {display: block; width: 100%; padding: 2px 0 3px 0; text-align: center; background-color: #666; color: #fff;}

.flexslider{ background:none; border:0; box-shadow:none}
/*.specs{ font-family:'Dado'; font-size:1.2em}*/
.info-box{ background:#fff; padding:40px}

.arrow{ font-family:'VolkswagenReg'; font-size:1.5em; position:relative; display:inline}
.arrow:before{ content:''; display:block; position:absolute; left:-30px; top:12px; background:url(../images/sprite.png) -50px -80px; width:30px; height:12px}
.arrow.down:before{ background-position:-30px -80px; width:12px; height:21px; left:-25px; top:6px}
.arrow.right:before{ left:auto; right:-40px}
.arrow.after:before{ left:auto; right:-36px; top:3px }
.arrow.after{ margin-right:40px}
a.arrow{ text-decoration:none}
a.arrow:hover{ text-decoration:underline}
h2 .arrow{ font-size:1em}
h2 .arrow:before{ top:5px}
.info-bar .arrow{ float:left; display:inline-block; }

.info-bar{ line-height:35px; position:relative; margin:0 -70px;}
.info-bar .inner{ padding:0 70px;}
.info-bar:before,
.info-bar:after{ content:''; display:block; background:url(../images/line.png); width:100%; height:3px; z-index:999}
.info-bar:before{ position:absolute; top:-3px; left:0}

.thumb{ width:33.333%; text-align:center; position:relative; float:left;
	.view-display-id-press &{ width:calc(100%/6);}
}
.thumb .inner{ padding:0 10px 20px; position:relative}
.thumb a{ display:block; height:100%; position:relative; padding-bottom:100%;}
.thumb a img{ position:absolute; left:0;
	.view-display-id-press &{
		left:50%; top:50%; transform:translate(-50%,-50%);
	}
}
.thumb a:hover img,
.thumb:hover{ border-radius:99em}

.view-portfolio{
	&.loading{
		background:url('../images/ajax-loader@2x.gif') 50% 35vh no-repeat; background-size:16px 15px; min-height:70vh;
	};
	.view-content{
		overflow:hidden;
	};
	.thumb{
		border-radius:0 !important;
		//&.in-view,
		&.entry-visible{
			a{
				opacity:1;
			}
		}
		a{
			padding:0; height:auto; opacity:0;
			&:before{
				@include transition();
				@include pseudo();
				opacity:0; background:rgba(#000, 0.6); width:100%; height:100%;
			}
			&:hover{
				&:before{
					opacity:1;
				}
			}
		}
		img{
			position:static; border-radius:0 !important;
		}

	}
}


/* SHARE
----------------------------------*/
/*#share{ font-family:'Dado'; font-size:1.2em}*/
#share .title{ display:inline-block; position:relative; top:2px}
#share a{ display:inline-block; text-indent:-999em; height:22px; background:url(../images/sprite.png); cursor:pointer; opacity:0.8; position:relative; top:4px}
#share a:hover{ opacity:1}
#share a.pinterest{ width:22px; background-position:0 -105px}
#share a.twitter{ width:26px; background-position:-22px -105px}
#share a.google{ width:23px; background-position:-48px -105px}
#share a.facebook{ width:18px; background-position:-71px -105px}
#share a.tumblr{ width:18px; background-position:-91px -105px}
.info-bar #share{ float:right;}

#twitter, #facebook, #facebook .fb_iframe_widget{float:left; display:inline !important; margin-right:20px}
#tumblr{ float:left; margin-left:30px}
#facebook span{ display:block; }


/* MAILCHIMP FORM
----------------------------------*/
#block-mailchimp-signup-quick-signup {padding: 0 0 0 30px; }
#block-mailchimp-signup-quick-signup h2 {/*font-family:'Dado'; font-size:1.2em;*/ margin-bottom:15px; }
#block-mailchimp-signup-quick-signup input[type=text] {-webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; border: 1px solid black; padding:5px 10px; }
#block-mailchimp-signup-quick-signup input[type=submit] {font-size:1.5em; border:none; background:#000; color:#fff; padding:2px 10px; text-decoration:none; line-height:normal;}


/* RESPONSIVE VIDEO
----------------------------------*/
.fluid-width-video-wrapper { padding:0; position: relative; width: 100%; padding-bottom:56.25%}
.fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object, .fluid-width-video-wrapper embed { height: 100%; width: 100%; position: absolute; top: 0; left: 0;  }


/* ------------------------------ */
/* CONTENT TYPES
/* ------------------------------ */

/* FRONT
----------------------------------*/
.front.domain-id-1{
	#main{ margin:0}
	#overlay{ opacity:1; background:#000; width:100% !important; height:100vh; height:calc(var(--vh,1vh) * 100); }
	#overlay div,
	ul.slides,
	ul.slides li{ width:100%; height:100%; margin:0; padding:0}
	.flexslider li{
		@include objectFitImage();
		position:absolute;
	}
	#header,
	#sidebar,
	#page-content{ display:none}
	#nav{ right:0; position:relative; float:right;}
	#nav:before{ left:-5px; display:block}
	#nav > .inner{ margin-left:-4px}
}

.front #home{ position:absolute; top:0; left:70px; z-index:99; max-width:390px; height:100vh; height:calc(var(--vh,1vh) * 100); width:100%;
	#logo-home{ position:absolute; bottom:70px;}
}
.view-news.view-display-id-home.view-display-teaser{ margin-top:30px;
	img{
		display:none;
	}
}
.view-news.view-display-id-home.view-display-teaser .info-box{ min-height:0;}
.view-news.view-display-id-home.view-display-teaser .field-name-field-intro{ margin-bottom:25px; padding:0}
.view-news.view-display-id-home.view-display-teaser a.read-more{ position:static; bottom:0; }


/* PAGE
----------------------------------*/
.node-page > .field-name-field-intro{ opacity: 0}
.field-name-field-intro p{ margin:0; line-height:1.25; }
.view-mode-full .field-name-field-intro p{ font-size: 1.9em;}
.field-name-field-intro,
.node-news-press .field-name-body,
.line{ background:url(../images/line.png) left bottom no-repeat; padding-bottom:50px; margin-bottom:45px; position:relative; overflow:hidden; }
.line.regfont p, .page-node-62 .line.regfont p {font-size:inherit; }
.line.dblbtm {padding-bottom:100px; }
.field-name-body{ opacity:0}

.view-page-image .views-row{ text-align:center}
.view-page-image img, .image-sidebar, .page-circle img { border-radius:450px;}

/* AGENCIES/CLIENTS */
.page-node-61 .left{ float:left; width:50%}
.page-node-61 h2{ margin-top:0}
.page-node-61 .left p{ font-size:1.5em}
/* CONTACT */
.page-node-62 .line p{ font-size:2em;font-family:'VolkswagenReg'}
.page-node-62 .line .icons-set {margin-top:0; }
/* ABOUT */



/* ARTWORK
----------------------------------*/
.node-type-artwork #main{margin-bottom:0}
#artwork{ position:absolute; z-index:999; width:100%; background:#fff; opacity:0; margin:0 auto; background:#fff }
#artwork.notloaded > .inner{ padding:0}
#artwork > .inner{ padding:60px 30px 0}
#artwork.fade > .inner{ opacity:0.6}
#artwork.active{ opacity:1}
#artwork.notloaded .close, #artwork.notloaded .artwork-pager{ display:none}
#artwork .close{ text-indent:-999em; display:block; width:32px; height:30px; background:url(../images/sprite.png) 10px -80px; position:absolute; top:20px; right:30px; z-index:30; cursor:pointer }
#artwork .artwork-pager{ display:block; text-indent:-999em; width:35%; height:12px; position:absolute; top:50px; z-index:25; cursor:pointer}
#artwork #artwork-prev{ left:30px; background:url(../images/arrow_left.png) 0 50% no-repeat; }
#artwork #artwork-next{ right:30px; background:url(../images/arrow_right.png) right 50% no-repeat;}
#artwork #artwork-prev:hover{ left:28px}
#artwork #artwork-next:hover{ right:28px}
#artwork .info-bar{ margin:0 -30px}
#artwork-info-bar .inner,
#artwork-project-bar .inner{ padding-left:100px}
#artwork-info-bar .mobile{ display:none}
img.lazy{ width:100%}

#artwork-image{ text-align:center; /*max-width:1000px;*/ margin:0 0 60px; position:relative;}
#artwork-image img{ max-height:100%; position:absolute; margin:auto; top:0; right:0; bottom:0; left:0}
.node-artwork h1,
.node-artwork h2{ margin-bottom:15px; margin-top:40px; font-size:2em}
#artwork-details,
#artwork-project-details{ text-align:center; max-width:560px; margin:0 auto 50px; padding:0 10px }

/* IMAGES POPUP */
.page-images #artwork-info-bar{ text-align:center; position:fixed; left:0; bottom:0; margin:0; width:100%; background:#fff; }
.page-images #artwork-info-bar:after{ display:none}
.page-images .info-bar .arrow{ float:none}
.page-images .info-bar .arrow:before{ display:none}


/* NEWS/PRESS
----------------------------------*/
.node-type-news-press #main{ margin-bottom:50px}
.node-news-press .field-name-field-intro{ background:none; padding:0; margin:0}
.node-news-press.view-mode-full .field-name-field-intro{ opacity:0}
#share-like{ padding-top:30px}
.node-news-press .field-name-body{ background:url(../images/line.png) left top no-repeat, url(../images/line.png) left bottom no-repeat; padding-top:50px; margin-top:45px}
.node-news-press .field-name-body h2{ margin-top:0}
#artwork-reference{ margin:0 auto; max-width:250px; text-align:center}
#artwork-reference h2{ margin-bottom:10px}
#artwork-reference a.arrow{ font-family: 'VolkswagenMed';}
#artwork-reference .arrow:before{ display:none}
#artwork-reference .arrow span{ position:relative}
#artwork-reference .arrow span:after{ content:''; position:absolute; top:5px; right:-40px; display:block; background:url(../images/sprite.png) -50px -80px; width:30px; height:12px}
.field-name-field-extra-content .field-name-field-intro{ background:none; padding:0; margin:0}
.field-name-field-extra-content .field-name-field-intro p{ /*font-family:'Dado'; font-size:1.2em;*/ margin-bottom:15px}
.field-collection-view{ margin:0 0 45px; padding:0; border:none}
.field-collection-container{ margin:0; padding:0; border:none}
.field-collection-container .field-items .field-item{ margin-bottom:0}
.field-collection-container .field-items .field-item:last-child .field-collection-view{ background:url(../images/line.png) 0 bottom repeat-x; padding-bottom:50px;}
#tags{ margin-bottom:30px}
#tags ul, #tags li{margin:0; padding:0; list-style:none; float:left}
#tags ul{ display:block; float:left; width:70%}
#tags ul li{ float:left; margin:0 5px 5px 0}
#tags .arrow{ float:left; display:inline-block}
#tags .arrow:before{ top:6px}
#tags a{ background:#000; color:#fff; display:inline-block; text-decoration:none; padding:3px 7px}
#tags a:hover{ opacity:0.9}
#news-info-bar{ position:fixed; bottom:-3px; left:0; width:100%; margin:0; background:#fff; z-index:1;
	.menu-open &{
		display:none;
	}
}
#news-info-bar .inner{ padding-left:100px}


/* CASE STUDY
----------------------------------*/

.fade-in{
	opacity:0;
	&.animate{
		@include fadeIn();
		opacity:1 !important;
	}
}

.listing{

	&.case-studies{
		.node{
			opacity:0;
		}
	}

	list-style:none; margin:0; padding:0;
	li{
		list-style:none; margin:0; padding:0;
	}
	.box{ position:relative; margin-bottom:20px}
	.content{ opacity:0; background:rgba(#000, 0.6); display:block; position:absolute; top:0; left:0; width:100%; height:100%;}
	.inner{ position:absolute; top:45%; left:0; width:100%; transform:translateY(-50%); padding:0 15px}
	h2, h3{ color:#fff; margin:0; padding:0; text-align:center; line-height:1; font-family:$light}
	h2{ @include fontsize(10); margin-bottom:10px}
	h3{ @include fontsize(20)}
	a{ text-decoration:none}
	li:hover,
	.node:hover{
		.content{ opacity:1;}
		.inner{ top:50%}
	}
}

.case-studies-more{ text-align:center; padding:0 70px 70px;
	.wrapper{ position:relative; height:100%;}
	h2.arrow.down{ display:block; margin:0 0 20px 0; @include fontsize(25); position:absolute; width:100%; top:25%;
		&:before{ left:50%; margin-left:-6px; top:auto; bottom:-35px; }
	}
	.listing{ position:absolute; width:100%; bottom:0}
	.node:nth-child(3){ display:none}
}

.node-type-case-study{

	#main{ padding:0; margin:0}
	h1{ @include fontsize(37);}
	h1, h2{ line-height:1; font-family:$light}
	#toggles{ position:absolute; width:100%; max-width:1600px; margin:0 auto; top:0;
		#menu-toggle{ top:22px; right:70px}
	}

	.full-height{ height:95vh; min-height:650px; position:relative; z-index:1; background-position:right bottom; background-size:cover; clear:both;
		&.slideshow{ min-height:790px; max-height:790px}
	}

	.layout-case-study{ height:100%;
		.text, .image{ height:100%; position:relative; }
		.body a.arrow-link{ display:inline-block}
		.text, .image .wrap{ padding:70px;}
		.text{ float:left; width:33.333%; margin:0;
			&.White, &.white{ color:#fff;
				a{ color:#fff}
			}
			&.line{ background:url(../images/line_vert_new@2x.png) right 0 repeat-y; background-size:2px auto; margin-bottom:0;
				&.White{ background-image:url(../images/line_vert_white@2x.png); background-size:2px auto;}
			}
		}
		&.Right{
			.text{ float:right;
				&.line{ background-position:0 0}
			}
			.image{ float:left}
		}
		.image{ float:right; width:66.666%; overflow:hidden;
			&.line{ background:url(../images/line_vert.png) 0 0 repeat-y; margin-bottom:0}
			.wrap{ position:absolute; width:100%; text-align:center}
			&.Top .wrap{ top:0;}
			&.Bottom .wrap{ bottom:0}
			&.Middle .wrap{ bottom:50%; transform:translateY(50%)}
			&.Bottom img{ max-width:350px; margin:0 auto}
		}
		&.text-image{
			.image{
				/*img{ max-width:65%} */
				&.phone-ipad{
					.wrap{ height:100%}
					img{ max-width:100%}
					.phone{ position:absolute; left:15%; bottom:0; width:25%}
					.ipad{ position:absolute; right:5%; bottom:0; width:55%}
				}
			}
		}
		&.slideshow.full-screen{
			&.slick-slider,
			.slick-slider{ margin:0}
			.slick-slide{ position:relative; height:95vh;}
            picture, img{ @include cover; @include object-fit(cover); z-index:1}
           .text{ z-index:2;}
			.slick-dots{ bottom:30px; margin:0;
              &.black li button{ background:#000}
              li{ width:auto; height:auto; border-radius:100%; margin:0 10px; border:1px solid #fff;
                  &.slick-active{
                      button{ background:#000}
                  }
                  button{ width:15px; height:15px; border-radius:100%; background:#fff; @include transition(background);
                      &:before{ display:none}
                  }
              }
			}
			.text{ float:none; position:absolute; top:0; left:0; height:auto}
			.field-collection-view{ margin:0}
			.field-collection-view-links{ display:none}
		}
		&.video{ position:relative;
			&.White{ color:#fff}
			h2{ @include cover; max-width:600px; padding:0 20px; right:0; bottom:0; margin:auto; text-align:center; height:37px; z-index:2; cursor:pointer;}
			.video-player{ position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background:#000; display:none;
				.inner{ position:relative; top:50%; transform:translateY(-50%); margin:0 auto; max-width:1080px; }
				.video-wrap{ position:relative; padding-bottom:56.25%; max-height:95vh;
					iframe{ @include cover;}
				}
			}
		}
	}

	.background-image .layout-case-study{ position:relative;
		.text{ float:none; position:absolute; top:0; left:0; width:33.333%; z-index:2}
		&.Right .text{ left:auto; right:0;}
		img{ position:absolute; top:0; left:0; width:100%; height:100%; @include object-fit(cover); z-index:1; }
	}

}

#intro{
	@include line;
	#header{ max-width:none}
	.layout-case-study{ position:relative; height:calc(100% - 69px); z-index:1;
		.text{ padding-top:0; padding-bottom:0; width:50%;}
		.image{ width:50%}
		.inner{ position:relative; height:100%}
		.middle{ position:absolute; top:45%; transform:translateY(-50%)}
		.logo{ position:absolute; bottom:70px; max-width:100px}
		.body p{ @include fontsize(13)}
	}
	.background-image{
		@include cover();
		@include objectFitImage();
		position:absolute; z-index:0;
	}
}

.field-name-field-modules > .field-items > .field-item:not(:last-child),
.field-name-field-modules{ @include line }

.node-module{
	@include line;
	h2{ margin-top:0; @include fontsize(22.5); margin-bottom:25px}
	p,ul,ol{ @include fontsize(10);
		&:not(:last-child){ margin-bottom:25px;}
	}
	ul, ol{ margin-left:12px; font-family:$light; @include fontsize(7.5)}
}

.slick{
	&.hidden{ display:none;}
	.slick-arrow{ width:26px; height:13px; background-size:26px 13px; top:auto; bottom:0; z-index:99;
		&:before{ display:none}
	}
	.slick-prev{ background-image:url(../images/arrow_left@2x.png); left:15px }
	.slick-next{ background-image:url(../images/arrow_right@2x.png); right:15px}
	&.full-screen{
		.slick-prev{ bottom:0;}
		.slick-next{ bottom:0; }
	}
	&.White{
		.slick-prev{ background-image:url(../images/arrow_left_white@2x.png);}
		.slick-next{ background-image:url(../images/arrow_right_white@2x.png);}
	}
}

.website{
	.image{ padding:70px 0}
	.design{ position:relative; overflow:hidden}
	.slick { text-align:center; margin:0 auto;
		.slick-slide{ padding:0 70px}
		&.desktop .slick-slide{ padding-right:0}
		.design{ cursor:zoom-in}
		.phone{ width:285px; height:585px; background:url(../images/iphone.png); background-size:285px 585px; margin:0 auto;
			.design{ width:248px; height:441px; background-repeat:no-repeat; background-size:248px auto; top:71px; left:18px;}
		}
		.ipad{ position:relative; background:url('../images/ipad.png') no-repeat; background-size:auto 100%; height:585px; width:840px; margin:0 auto; overflow:hidden;
			.design{ width:680px; height:511px; left:76px; top:37px; background-size:100% auto; }
		}
		.zoom{ cursor:pointer; @include fonticon('\e900',20px,#000); width:20px; height:20px; margin:15px auto 0;}
		h3{ margin:15px 0 0}
		&.desktop{
			.zoom,
			h3{ transform:translateX(-35px)}
		}
	}
	&.White{ color:#fff;
		.zoom:before{ color:#fff}
	}
}

#artwork{
	.node-type-case-study &{
		display:none;
	}
	.node-type-case-study.popup &{
		display:table; height:100%;
		.inner{
			display:table-cell; vertical-align:middle;
			background:url('../images/ajax-loader@2x.gif') center center no-repeat; background-size:15px 15px;
		}
	}
	&.mobile,
	&.desktop{
		> .inner{ padding:40px 30px}
		#load{ margin:0 auto;}
		.close{ position:fixed; }
		.artwork-pager{ top:50%; position:fixed; }
	}
	&.mobile{
		#load{ max-width:320px;}
	}
	&.desktop{
		#load{ max-width:1024px; }
	}
}

body.overflow-hidden{ overflow:hidden};

.links{ margin-top:80px; @include fontsize(8);
	.view{
		&:after{ content:''; width:21px; height:12px; background:url(../images/arrow_nav@2x.png) 0 1px no-repeat; background-size:21px 12px; display:inline-block; margin:0 5px;}
	}
	.slick-toggle{ background:#000; display:inline-block; color:#fff; padding:1px 8px;  text-decoration:none;
		&:hover{ background:rgba(#000,0.5)}
	}
	.White &{
		.view:after{ background-image:url(../images/arrow_nav_white@2x.png)}
	}
}

a.arrow-link{
	text-decoration:none;
	&:after{
		@include transition();
		content:''; width:21px; height:12px; background:url(../images/arrow_nav@2x.png) 0 1px no-repeat; background-size:21px 12px; display:inline-block; margin:0 5px;}
	.White &{
		&:after{ background-image:url(../images/arrow_nav_white@2x.png)}
	}
}





/* ------------------------------ */
/* VIEWS
/* ------------------------------ */

/* GLOBAL VIEW STYLES
----------------------------------*/
.view.thumbs{ margin:0 -10px}

.view:not(.view-portfolio):not(.view-images) .views-row{ position:relative; }
.views-field-edit-node a{ position:absolute; right:1px; top:1px; font-size:.9em; text-transform:uppercase; display:inline-block; padding:4px 8px; text-decoration:none; z-index:2; line-height:1; color:#fff; background:#000; display:inline; height:auto }
.views-field-edit-node a:hover{ opacity:0.6}


/* LATEST NEWS
----------------------------------*/
.view-news.view-display-teaser .full-width, .view-display-taxonomy-teaser .full-width{ padding:30px; margin:0 auto 20px auto; background:right center no-repeat; background-size:cover; min-height:290px; @include objectFitImage(cover,right center); }
.view-news.view-display-teaser .info-box, .view-display-taxonomy-teaser .info-box{ max-width:470px; min-height:290px; position:relative; z-index:2;}
.view-news.view-display-teaser h2, .view-display-taxonomy-teaser h2{ margin-top:0;}
.view-news.view-display-teaser .field-name-field-intro, .view-display-taxonomy-teaser .field-name-field-intro{ margin:0; padding:0 0 30px; background:none}
.view-news.view-display-teaser .field-name-field-intro p, .view-display-taxonomy-teaser .field-name-field-intro p {/* font-family:'Dado'; font-size:1.2em; line-height:1.8em*/}
.view-news.view-display-teaser a.read-more, .view-display-taxonomy-teaser a.read-more{ font-size:1.5em; text-decoration:none; position:absolute; bottom:40px; line-height:1}
.view-news.view-display-teaser a.read-more:hover, .view-display-taxonomy-teaser a.read-more:hover{ text-decoration:underline}

.block-simple-instagram-feed{
	text-align:center;
	a.arrow{
		margin-top:40px; display:inline-block;
	}
}
.instagram_gallery{
	margin:0 -10px;
	@include breakpoint-custom(960px){
		margin:0 -7px;
	}
	@include breakpoint-custom(600px){
		margin:0 -5px;
	}

	> a{
		width:25%; display:block;float:left; position:relative; padding:0 10px 20px;

		&:before{
			@include transition();
			@include pseudo();
			opacity:0; left:10px; background:rgba(#000, 0.6); width:calc(100% - 20px); height:calc(100% - 20px);
		}

		@include breakpoint-custom(1024px){
			width:33.333%;
		}
		@include breakpoint-custom(960px){
			padding:0 7px 14px;
			&:before{
				left:7px; width:calc(100% - 14px); height:calc(100% - 14px);
			}
		}
		@include breakpoint-custom(600px){
			width:50%; padding:0 5px 10px;
			&:before{
				left:5px; width:calc(100% - 10px); height:calc(100% - 10px);
			}
		}

		&:hover{
			&:before{
				opacity:1;
			}
		}

	}
}

.field-collection-container .field-name-field-image{
	opacity: 0;
}

/* HOME */
.view-news.view-display-id-home.view-display-teaser .full-width{ background:none !important; padding:0; min-height:inherit; margin:0}


/* PRESS COVERAGE
----------------------------------*/
.view-news.view-display-id-press a.press-item{ display:block; background:#e4e4e4}
.view-news.view-display-id-press a.press-item:hover{ border-radius:999em}


/* EXHIBITIONS
----------------------------------*/
.view-exhibitions .views-row{ margin-bottom:25px}
.view-exhibitions .views-field{ float:left}
.view-exhibitions .views-field-title{ font-size:1.5em; line-height:1.2; width:10%}
.view-exhibitions .views-field-body{ width:90%}
.view-exhibitions h2:first-child{ margin-top:0}
.view-exhibitions h3{ margin:0; font-size:1.5em}
.view-exhibitions p{ font-size:1.5em; color:#999999}


/* TWITTER
----------------------------------*/
.view-tweets ul{ margin:0; padding:0; list-style:none}
.view-tweets ul li{ margin-bottom:10px}
.view-tweets .views-row{
	opacity:0;
}
.view-tweets .views-row a{ text-decoration:none}
.view-tweets .views-row a:hover{ text-decoration:underline}
.view-tweets .views-field-text{ font-size:1.15em; }
.view-tweets em{ font-style:normal}
.view-tweets .views-field-created-time,
.view-tweets .views-field-created-time a{ color:#999999; }


/* INSTAGRAM
----------------------------------*/
div#views_infinite_scroll-ajax-loader,
.views_infinite_scroll-ajax-loader{ background:url(../images/ajax-loader.gif) center center no-repeat; height:15px}
div#views_infinite_scroll-ajax-loader img,
.views_infinite_scroll-ajax-loader img{ display:none}
.section-instagram-feed{
	#page-content{ float:none; width:auto;}
	#sidebar{ display:none}
}
.view-instagrams{ text-align:center;
	.view-content{ margin-bottom:40px;
		a{ position:relative; padding-bottom:100%; overflow:hidden; @include objectFitImage()}
		.likes{ position:absolute; top:0; left:0; right:0; bottom:0; margin:auto; width:80px; height:80px; font-size:1.5em; z-index:2;text-transform:uppercase; text-align:center; padding-top:10px; opacity:0; color:#000; @include transition; background:#fff; border-radius:99em}
		.likes span{ font-size:3em; display:inline-block; margin-top:19px; }
		a:hover{
			.likes{ opacity:1}
		}
		img{
			z-index:1;
		}
	}
}



/* TAXONOMY TERMS
----------------------------------*/
.view-taxonomy-term {}
.view-taxonomy-term .view-header { }
.view-taxonomy-term .sidebar-hack {position:absolute; top:0; right:0; width:45%; }
.view-taxonomy-term .sidebar-hack .page-circle {width:100%; }
.view-taxonomy-term .page-circle {width:45%; margin:0; float:right;  }
.view-taxonomy-term .view-header .taxonomy-term-description {
  width:52%;
}
/*.view-taxonomy-term .view-content .views-row-first {*/
  /*background: url(../images/line.png) left top no-repeat;*/
  /*padding-top: 40px;*/
  /*margin-top: 35px;*/
  /*max-width:820px;*/
/*}*/
.view-taxonomy-term .views-row {padding-bottom:30px; padding-top:10px; max-width:820px; }
.view-taxonomy-term .views-row .field-name-field-image {margin-bottom:15px; }
.view-taxonomy-term .view-header .taxonomy-term-description,
.view-taxonomy-term .view-content .views-row-last,
.view-taxonomy-term .view-header .tag-bar {
  background: url(../images/line.png) left bottom no-repeat;
  padding-bottom: 50px;
  margin-bottom: 45px;
  max-width:820px;
}
.view-taxonomy-term .views-row .field-name-field-intro {
  margin-bottom:15px;
}
.view-taxonomy-term .view-header .taxonomy-term-description p {font-size: 2em; margin:0; line-height:1.25; }
.view-taxonomy-term .view-header .taxonomy-term-description p:not(:last-child){ margin-bottom:20px}
.view-taxonomy-term .view-mode-copy_only p {/*font-family:'Dado'; font-size:1.2em; line-height:1.8em;*/ }
.view-taxonomy-term .field-name-node-link a {font-size:1.6em; font-family:'VolkswagenReg'; text-decoration:none; font-weight:bold; }
.view-taxonomy-term .field-name-node-link a:hover {text-decoration:underline; }
#taxonomy-project-bar .inner {padding-left:100px; overflow:hidden; margin-top:40px; }
#taxonomy-project-bar .inner .social_list {float:right; margin-top:4px; }

/* Tag bar
----------------------------------*/
.tag-bar {overflow:hidden; clear:both; }
.tag-bar .tag-bar-title {
  font-family: 'VolkswagenReg';
  font-size: 1.5em;
  position: relative;
  display: inline;
  float:left;
  margin:1px 0;
}
.tag-bar .tag-bar-arrow {
  background: url(../images/sprite.png) -50px -80px;
  display:block;
  width:20px;
  height:20px;
  float:left;
  margin:5px 10px;
}
.tag-bar .tag-list {float:left; margin:0; }
.tag-bar .tag-list-item {background:black; display:inline-block; padding:0px 5px; margin:0 10px 0 0; border:1px solid black; }
.tag-bar .tag-list-item:hover {border: 1px solid white; }
.tag-bar .tag-list-item a {color:white; text-decoration:none; }

.sidebar-link {font-size:1.6em; font-family:'VolkswagenReg'; text-decoration:none; font-weight:bold; text-align:center; display:block; margin-top:30px; }
.sidebar-link .sidebar-arrow {
  background: url(../images/sprite.png) -50px -80px;
  display:inline-block;
  width:20px;
  height:13px;
  margin-left:10px;
}
.sidebar-notes {/*font-family:'Dado'; font-size:1.2em; line-height:1.8em;*/ text-align:center; }


