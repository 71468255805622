/**
 * Fonts
 */

$font: 'Open Sans';
$heading: 'Varela Round';
$light: 'VolkswagenLight';

$baseFontSize: 14;
$baseLineHeight: 1.5;


/*
 * Colors
 */
$grey: #4f5155;
$grey_dark: #2f292b;
$grey_bg: #cacaca;
$grey_bg_mid: #f2f2f2;
$grey_bg_light: #f0f0f0;
$grey_box: #f0f1f1;
$black: #060606;
$red: #d71920;
$blue_icon: #1a2c4e;
$blue_bg: #d9e3f5;
$border: #d0d2d2;
$border_light: #999595;


@mixin breakpoint-custom($max-width){
    @media all and(max-width:$max-width){
        @content
    }
}


/*
 * Grid
 */
$gutter: 20px;

// Container Width
$contentWidth: 1200px;

// The amount lists, blockquotes and comments are indented.
$indent: 30px;



/*
 * Mixins
 */ 

@mixin pseudo(){
	content:''; display:block; position:absolute;
}

@mixin reset(){
	margin:0; padding:0; list-style:none;
	&>li{ margin:0; padding:0; list-style:none;}
}
 
@mixin container($width: $contentWidth){
    margin: 0 auto;
    max-width: $width;
}

@mixin heading(){
	font-family:$heading;
	text-transform:uppercase;
}

@mixin verticalAlign(){
    display:table; width:100%; height:100%;
    .cell{ width:100%; height:100%; vertical-align:middle; }
}

@mixin line(){ position:relative;
	&:after{ content:''; display:block; height:4px; background:url(../images/line_new.png) repeat-x; position:absolute; bottom:-2px; width:100%; z-index:99; opacity:0.7}

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
        &:after{
            background-image:url(../images/line@2x.png);
            background-size:auto 2px;
        }
    }

}

@mixin button($color: $red, $hover: #fff){
	border:1px solid $color; color:$color; display:inline-block; padding:12px 15px; text-decoration:none !important; line-height:1; font-family:$heading; text-transform:uppercase; @include fontsize(); 
	&:hover{ opacity:1; background:$color; color:$hover; }
}

@mixin fontsize($fontSize: $baseFontSize){
    font-size: #{$fontSize}px;
    font-size: #{$fontSize / 10}rem;
}

@mixin font($font,$size,$color: $grey,$lineheight:$baseLineHeight,$weight:normal,$style:normal) {
    font-family: $font;
    @include fontsize($size);
    color: $color;
    line-height: $lineheight;
    font-weight: $weight;
    font-style: $style;
}

@mixin cornerborder ($radius){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius; 
    border-radius: $radius;
    background-clip: padding-box;
 }


@mixin gradient($start,$end){
    background-color: $end;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start), to($end)); /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, $start, $end); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image:    -moz-linear-gradient(top, $start, $end); /* Firefox 3.6-15 */
    background-image:      -o-linear-gradient(top, $start, $end); /* Opera 11.10-12.00 */
    background-image:         linear-gradient(to bottom, $start, $end);
}

@mixin gradienthorizontal($start,$end){
    background-image: -webkit-gradient(linear, left top, right top, from($start), to($end)); /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(left, $start, $end); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image:    -moz-linear-gradient(left, $start, $end); /* Firefox 3.6-15 */
    background-image:      -o-linear-gradient(left, $start, $end); /* Opera 11.10-12.00 */
    background-image:         linear-gradient(to right, $start, $end);
}

@mixin shadow ($hor, $ver, $blur, $spread_radius, $color) {
    -webkit-box-shadow: $hor $ver $blur $spread_radius $color;
    -moz-box-shadow: $hor $ver $blur $spread_radius $color;
    box-shadow: $hor $ver $blur $spread_radius $color;
}


@mixin transition ($property: all, $duration:.2s, $ease: linear) {
    -webkit-transition: $property $duration $ease;
    -moz-transition: $property $duration $ease;
    -ms-transition: $property $duration $ease;
    -o-transition: $property $duration $ease;
    transition: $property $duration $ease;
}

@mixin fadeIn(){
    @include transition(all,0.8s);
}

@mixin translate3d ($first, $second, $third) {
    -webkit-transform: translate3d($first, $second, $third);
    -moz-transform: translate3d($first, $second, $third);
    -o-transform: translate3d($first, $second, $third);
    -ms-transform: translate3d($first, $second, $third);
    transform: translate3d($first, $second, $third);
}

@mixin cover(){
    width:100%; height:100%; position:absolute; top:0; left:0;
}

@mixin object-fit($fit: fill, $position: null){
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

@mixin objectFitImage($fit: cover, $position: null){
    position:relative;
    display:block;
    img{
        @include cover;
        @include object-fit($fit, $position);
    }
}

@mixin cover(){
    position:absolute; top:0; left:0; width:100%; height:100%;
}


/*
 * Nesting
 */
.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin clearfix(){
	&:after {
    content: "";
    display: table;
    clear: both;
  }
}



/*
 * Icon Fonts
 */ 
@mixin fonticon ($content, $size:24px, $color: #000, $top: 0, $left: 0, $right: 0, $bottom: 0) {
    position:relative;
    &:before {
        position: absolute;
        content: $content;
        color: $color;
        font-size:$size;
        font-family:'icomoon';
        font-style:normal;
        font-weight:normal;
        speak:none;
        display:block;
        text-decoration:inherit;
        width:$size;
        height:$size;
        text-align:center;
        -webkit-font-smoothing:antialiased;
        font-smoothing:antialiased;
        text-rendering:optimizeLegibility;
        line-height: $size;
        float: left;
		top: $top;
		left: $left;
		right: $right;
		bottom: $bottom;
        
    }
}




/*
 * Transparent Gradient
 */
@mixin transparentGradient(){
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}



