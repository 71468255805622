
@media (min-width: 1920px) {

	#intro{
		.layout-case-study{
			.logo{ bottom:140px;}
		}
	}
	.case-studies-more{ padding:0 140px 140px}
	.node-type-case-study{
		.layout-case-study {
			.text, .image .wrap{ padding:140px;}
		}
		.full-height.slideshow{ max-height:930px; }
	}
	.website{
		.image{ padding:140px 0}
		.slick .slick-slide{ padding:0 140px}
		.slick.desktop{
			.slick-slide{ padding-right:140px}
			.zoom,
			h3{ transform:translateX(0)}
		}
	}
}


@media (min-width: 1601px) {

	#main, #header, #toggles, #news-info-bar{ max-width:77%; margin-left:5%; margin-right:5%}
	#menu-toggle{ display:none}
	#nav, #filter{ right:-13%; width:13%}
	#nav{ right:0; }
	#nav:before{ display:block}

	#loading{ margin-left:calc(-6.5% - 15px);}

	.node-type-case-study{
		#intro,
		#main{ max-width:87%}
	}

	.filter-open #header, .filter-open #toggles, .filter-open #main,
	.filter-open #loading{ transform:translate3d(-0,0,0); -o-transform: translate3d(-0,0,0); -ms-transform: translate3d(-0,0,0); -moz-transform: translate3d(-0,0,0); -webkit-transform: translate3d(-0,0,0); }
	.filter-open #filter{ transform:translate3d(-100%,0,0); -o-transform: translate3d(-100%,0,0); -ms-transform: translate3d(-100%,0,0); -moz-transform: translate3d(-100%,0,0); -webkit-transform: translate3d(-100%,0,0); }

	.filter-open #page-content{ transform:translate3d(0,0,0); -o-transform: translate3d(0,0,0); -ms-transform: translate3d(0,0,0); -moz-transform: translate3d(0,0,0); -webkit-transform: translate3d(0,0,0); margin-right:0 }

	#news-info-bar{ max-width:83%; padding:0 0 0 2%; margin:0 }
	#news-info-bar .inner{ padding:0}
	#news-info-bar .arrow{ margin-left:30px}

	.node-type-case-study p{ font-size:1.5em}

}



@media (max-width: 1400px) {

	.layout-third{
		.grid{ width:50%;
			&:nth-child(odd){ padding-left:0; padding-right:$gutter/2; clear:left;}
			&:nth-child(even){ padding-right:0; padding-left:$gutter/2}
		}
	}

	.case-studies-more{
		h2.arrow.down{ top:10%}
	}

	.website{
		.slick{
			.ipad{ width:100%; }
		}
	}

}

@media (min-width:1401px){
	.case-studies-more .node:nth-child(3){ display:block; }
}


@media (max-width: 1200px) {

	/*.node-type-case-study{
		.layout-case-study{
			.text{ width:45%;}
			.image{ width:55%;}
		}
	}*/

}



@media (max-width: 1024px) {

	.icon-link,
	.icons-set .social_list {
	  display:block;
	  margin-top:20px;
	  clear: both;
	  float:left;
	}
	/* CONTACT */
	.page-node-62 .icons-set .social_list {
	  margin-top:0px;
	}

	/* CONTENT TYPES
	/* ------------------------------ */

	/* ARTWORK */
	.thumb{ width:50%;
		.view-display-id-press &{ width:33.333%;}
	}
	#artwork .close{ right:15px; top:15px}
	#artwork #artwork-prev{ left:15px; }
	#artwork #artwork-next{ right:15px; }
	#artwork #artwork-prev:hover{ left:13px}
	#artwork #artwork-next:hover{ right:13px}


	.listing{
		.content{ opacity:1; background:none; display:block; position:static; width:auto; height:auto; margin-top:20px}
		.inner{ position:static; width:auto; transform:translateY(0); padding:0 15px}
		h2, h3{ color:#000; }
	}

	.variant-wrap{ max-width:none;}

}



@media (max-width: 960px) {

	/* MAIN LAYOUT
	/* ------------------------------ */
	.width{ margin:0 30px}


	/* PAGE ELEMENTS
	/* ------------------------------ */
	.thumb .inner{ padding:0 7px 14px;}
	.info-bar{ margin:0 -30px; }

	/* TOGGLES */
	#floating-filter-toggle{ right:45px}
	.floating-toggle #floating-filter-toggle{ top:15px;}


	/* CONTENT TYPES
	/* ------------------------------ */

	/* ARTWORK */
	#artwork .close{ right:5px; top:10px}
	#artwork #artwork-prev{ left:5px; }
	#artwork #artwork-next{ right:5px; }
	#artwork #artwork-prev:hover{ left:3px}
	#artwork #artwork-next:hover{ right:3px}
	#artwork-info-bar .inner,
	#artwork-project-bar .inner,
	#news-info-bar .inner{ padding:0 30px 0 60px}


	/* MODULE */
	.node-type-case-study{
		#toggles #menu-toggle{ right:30px}
	}


	/* VIEWS
	/* ------------------------------ */

	.view.thumbs{ margin:0 -7px}

	/* EXHIBITIONS */
	.view-exhibitions .views-field-title{ width:15%}
	.view-exhibitions .views-field-body{ width:85%}

}



@media (max-width:900px){

    #intro{ background-image:none !important;
      .layout-case-study{ height:auto;
        .text{ padding-top:60px; width:auto}
        .image{ width:auto}
        .middle{ position:static; transform:none}
        .logo{ position:static; margin:60px auto}
      }
	  .background-image{
		  display:none;
	  }
    }

	.node-type-case-study{

		.full-height{ height:auto; min-height:inherit;
			&[style*='background-image'] .text{ padding-top:100px; padding-bottom:100px}
			&.slideshow{ /*max-height:930px*/ max-height:none}
		}

		.layout-case-study{
			.text, .image{ float:none !important; width:auto; }
			.text{  text-align:center; padding:60px 20px;
				&.line{ background:none !important}
				.inner{ max-width:inherit}
			}
			.image{
				.wrap{ position:static; padding-top:0; }
				&.line{ background:none !important;}
				&.Middle .wrap{ transform:translateY(0%)}
			}
			&.text-image{
				.image{
					img{ max-width:100%}
					&.phone-ipad{
						.wrap{ height:auto; padding-top:55%}
					}
				}
			}
			&.slideshow.full-screen{
              .slick-slide{ position:relative; height:auto;}
              picture, img{ width:auto; height:auto; position:static}
				.text{ position:static;
					&.White, &.white{ color:#000}
				}
			}
		}

		.background-image .layout-case-study{
			.text{ float:none; position:static; width:auto;
				&.White, &.white{ color:#000}
			}
			img{ position:static; width:auto; height:auto; }
			.field-name-field-background-image{
				@include objectFitImage(cover,top right);
				padding-bottom:100%;
			}
		}

		.background-color .layout-case-study{
			.text{
				&.White, &.white{ color:#fff !important}
			}
		}

		.website{
			.image{ padding-top:0;}
		}

	}

	.case-studies-more { padding:60px 20px;
		.wrapper{ height:auto}
		h2.arrow.down,
		.listing{ position:relative; top:auto; bottom:auto}
		h2.arrow.down{ margin:0 0 70px}
	}


	.website .slick .slick-slide{ padding-top:0}
	.slick.full-screen .background{ height:auto; min-height:inherit}
	.links{ margin-top:20px}

}


@media (max-width: 768px) {
  .view-taxonomy-term .views-row,
  .view-taxonomy-term .view-header .taxonomy-term-description {width:100%; }
}



@media (max-width: 767px) {

	.layout-third{
		.grid{ width:auto; float:none;
			&:nth-child(odd){ padding-left:0; padding-right:0}
			&:nth-child(even){ padding-right:0; padding-left:0}
		}
	}

	.website{
		.slick .zoom{ display:none}
	}

}



@media (max-width: 740px) {

  /* FRONT */
  .domain-id-1.front{
	   #overlay{ position:fixed !important; width:100% !important}
	   #logo{ margin-left:0}
	   #toggles{display:block; margin:0; z-index:3}
	   #menu-toggle{ top:0; width:124px; }
	   #menu-toggle:before{ background:url(../images/home_menu.png); width:124px; height:70px; display:block; content:''; position:absolute; top:0; left:0}
	   #menu-toggle a{ position:relative;top:10px; left:37px}
	   #nav{ right:-190px; position:absolute }
	  .menu-open #nav{ position:relative; float:right;}
	   #nav > .inner{ margin-left:0}
	  .menu-open #nav > .inner{ margin-left:-5px}
	   #nav:before{ left:0}
	  &.menu-open #nav:before{ left:-5px}
	   #home{ max-width:100%; left:0; text-align:center}
	  &.menu-open #home{ display:none}
	   #logo-home{ padding:0 20px; bottom:30px !important;}
	   .view-news.view-display-teaser .info-box{ min-height:inherit; max-width:inherit; padding:20px}
  }

  .icons-set {margin-top:0; }

  .view-taxonomy-term .sidebar-hack {position:relative; }
  .view-taxonomy-term .sidebar-hack {width:100%; overflow:hidden; }
  .view-taxonomy-term .sidebar-hack .page-circle {float:none; margin:0 auto; width:auto; max-width:280px; margin-bottom:20px; }
  .view-taxonomy-term .sidebar-hack .page-circle img {}
  .view-taxonomy-term .views-row,
  .view-taxonomy-term .view-header .taxonomy-term-description {width:100%; }

  #taxonomy-project-bar .inner .social_list {margin:0 auto; float:left; }
}



@media (max-width: 600px) {

	/* MAIN LAYOUT
	/* ------------------------------ */
	.width{ margin:0 10px}

	.sidebar-first #page-content,
	.sidebar-first #sidebar,
	.sidebar-first h1#page-title{ float:none; width:auto;}
	.sidebar-first #sidebar{ margin:0 auto 30px; max-width:280px}
	.sidebar-first #sidebar > .inner{ padding-left:0}


	/* PAGE ELEMENTS
	/* ------------------------------ */
	.thumb .inner{ padding:0 5px 10px}
	.info-box{ padding:20px}
	.info-bar{ text-align:center; margin:0 -10px; }
	.info-bar .inner{ padding-left:40px; padding-right:5px}

	/* TOGGLES */
	.filter-open #project-info-toggle{ display:none}
	#floating-filter-toggle{ right:20px}
	.floating-toggle #floating-filter-toggle{ top:10px;}


	/* CONTENT TYPES
	/* ------------------------------ */

	/* ARTWORK */
	#artwork > .inner{ padding:10px 0 0}
	#artwork-image{ margin-bottom:30px; margin-top:20px; z-index:24}
	#artwork #artwork-next{ background-position:right bottom}
	#artwork #artwork-prev{ background-position:left bottom}
	#artwork-info-bar .inner,
	#artwork-project-bar .inner{ padding:0 10px 0 10px}
	#artwork .info-bar{ margin:0}

	.page-images #artwork .artwork-pager{ top:82px}
	.page-images .info-bar .inner{ padding-left:35px; padding-right:35px}
	.page-images #artwork-info-bar .arrow{ top:0}

	/* NEWS */
	.node-type-artwork #main{ margin-top:10px}
	#artwork-reference{ display:none}
	#news-info-bar .inner{ padding:0 10px 0 40px }

	/* CASE STUDY */
	.node-type-case-study{

		h1{ @include fontsize(18);}
		#toggles #menu-toggle{ right:10px}

		.full-height.slideshow{ min-height:0; max-height:none}
		.website{

		}

	}

	.website{
		.image{ padding-bottom:60px;}
		.slick{
			.slick-arrow{ bottom:0}
			.slick-slide{ padding:0 20px}
			.phone{ height: 345px;
				.design { height: 273px }
			}
			.ipad{ height: 345px;
				.design {
					@include objectFitImage(cover, top left);
					height: 300px; top:23px; left:45px; width:401px;
				}
			}
			&.desktop{
				.zoom,
				h3{ transform:translateX(-10px)}
			}
		}
	}

	.node-module{
		h2{ @include fontsize(21);}
	}

	#intro{
		.layout-case-study{
			.body p{ @include fontsize(11)}
		}
	}


	/* VIEWS
	/* ------------------------------ */
	.view.thumbs{ margin:0 -5px}

	/* PORTFOLIO */
	.portfolio-back{
		text-align:left;
	}
	#project-info #project-info-toggle{
		left:auto; right:0;
	}

	/* LATEST NEWS */
	.view-news.view-display-teaser .full-width{ padding:200px 0 0; min-height:inherit;
		img{
			height:200px;
		}
	}
	.view-news.view-display-teaser .info-box{ max-width:none;  padding:20px 0 0; min-height:inherit}
	.view-news.view-display-teaser .field-name-field-intro{ margin:0 0 20px; padding:0}
	.view-news.view-display-teaser a.read-more{ position:relative; bottom:0}

	/* EXHIBITIONS */
	.view-exhibitions .views-field-title,
	.view-exhibitions .views-field-body{ width:100%}
	.view-exhibitions .views-field-title{ font-weight:bold}

}



@media (max-width: 599px) {

	.thumb{ width:50%;
		.view-display-id-press &{ width:50%;}
	}

    .layout-half{
      .grid{ width:auto; float:none;
        &:nth-child(odd),
        &:nth-child(even){ padding-left:0; padding-right:0}
      }
    }

	.node-type-case-study{

		.layout-case-study{
			.image {
				.wrap{ padding:0 20px 40px; }
			}
			&.text-image{
				.image{
					&.phone-ipad{
						.wrap{  padding-top:48%}
					}
				}
			}
		}
	}

}



@media (max-width: 480px) {

	/* PAGE ELEMENTS
	/* ------------------------------ */

	#project-info #project-info-toggle span.mobile-title{ display:inline}
	#project-info #project-info-toggle span.project-title{ display:none}


	/* CONTENT TYPES
	/* ------------------------------ */

	/* ARTWORK */
	.page-images #artwork-info-bar .arrow{ visibility:hidden; height:1px; overflow:hidden}


	/* NEWS */
	#news-info-bar .project-title{ display:none}

	.fullscreen-overlay{
		&.mobile{
			.img{ width:100%; }
			.close{ right:5px; transform:translateX(0);}
		}
	}

	.portfolio-filter a{
		margin:0 25px;
		&.active:before{
			display:none;
		}
	}

	table#cart-list td.qty-cell{ padding-right:0;}

}


@media (max-width: 400px) {

	/* TOGGLES */
	#filter-toggle .type{ display:none}

	.website{
		.slick{ max-width:none;}
		.slick-prev{ left:20px}
		.slick-next{ right:20px}
	}

}


@media (max-width: 320px) {

	.node-type-case-study .layout-case-study .image.Bottom img{ max-width:100%}

}


@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {


  #nav ul.menu li:before,
  #filter p.filter-title:before{ background-image:url(../images/arrow_nav@2x.png); background-size:21px 12px}

  #nav .link_signup {
    background-image:url(../images/signup/signup_btn_sides_ret.png);
    background-size:188px 73px;
    margin-left:5px;
  }
  #nav .link_signup .top {
    background-image:url(../images/signup/signup_btn_top_ret.png);
    background-size:188px 6px;
  }
  #nav .link_signup .bottom {
    background-image:url(../images/signup/signup_btn_bottom_ret.png);
    background-size:188px 10px;
  }

  .front #menu-toggle:before{ background-image:url(../images/home_menu@2x.png); background-size:124px 70px}

  .toggle a:after,
  #floating-filter-toggle,
  .arrow:before,
  #share a,
  #artwork .close,
  #artwork-reference .arrow span:after,
  #social ul li a,
  ul.social_list li a,
  .mailinglist .icon,
  .tag-bar .tag-bar-arrow,
  .sidebar-link .sidebar-arrow,
  .icon-link .icon,
  .fullscreen-overlay .close{
    background-image:url(../images/sprite@2x.png);
    background-size:183px 165px;
  }

  #artwork #artwork-prev{ background-image:url(../images/arrow_left@2x.png); background-size:26px 13px}
  #artwork #artwork-next{ background-image:url(../images/arrow_right@2x.png); background-size:26px 13px}

  .line,
  .field-name-field-intro,
  .node-news-press .field-name-body,
  #header,
  .info-bar:before,
  .info-bar:after,
  .field-collection-container .field-items .field-item:last-child .field-collection-view,
  .view-taxonomy-term .view-header .taxonomy-term-description,
  .view-taxonomy-term .view-content .views-row-last,
  .view-taxonomy-term .view-header .tag-bar,
  h2.line{
    background-image:url(../images/line@2x.png);
    background-size:auto 2px;
    background-repeat:repeat-x;
  }
  .info-bar:before{top:-1px}
  .node-news-press .field-name-body { background:url(../images/line@2x.png) left top no-repeat, url(../images/line@2x.png) left bottom no-repeat; background-size:auto 2px;}
  #nav:before, #filter:before,
  .node-type-case-study .layout-case-study .text.line,
  .node-type-case-study .layout-case-study .image.line{background-image:url(../images/line_vert@2x.png); background-size:5px auto; }
  div#views_infinite_scroll-ajax-loader, #loading { background-image:url(../images/ajax-loader@2x.gif); background-size:15px 15px}
}
