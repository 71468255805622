
.page-signup{

	#node-content {
	  background:url(../images/line.png) left bottom no-repeat;
	  padding-bottom:50px;
	  margin-bottom:45px;
	}
	
	/* FORM */
	.mailchimp-signup-subscribe-form {
	  overflow:hidden;
	}
	.mailchimp-newsletter-mergefields {
	  background:url(../images/line.png) left top no-repeat;
	  margin-top:45px;
	  padding-top:45px;
	}
	.mailchimp-signup-subscribe-form label, .mailchimp-signup-subscribe-form input[type=text] {
	  display:inline-block;
	  padding-top:0;
	}
	.mailchimp-signup-subscribe-form label {
	  width:35%;
	  clear:left;
	  font-size:2em;
	  font-weight:normal;
	  float:left;
	  font-family:'VolkswagenReg';
	}
	.mailchimp-signup-subscribe-form input[type=text], .mailchimp-signup-subscribe-form input[type=text]:focus {
	  border:none;
	  -moz-border-radius: 0px;
	  -webkit-border-radius: 0px;
	  border-radius: 0px;
	  border-bottom: 1px solid #E9E9E9;
	  font-size:1.5em;
	  width:65%;
	  outline:0;
	  float:right;
	}
	.mailchimp-signup-subscribe-form .form-required {
	  color:black;
	}
	.mailchimp-signup-subscribe-form .form-type-textfield {
	  overflow:hidden;
	  float:right;
	  clear:both;
	  display: table-cell;
	  vertical-align: bottom;
	  height: 50px;
	  margin:0;
	  width:100%;
	}
	.mailchimp-signup-subscribe-form [type=submit], .mailchimp-signup-subscribe-form [type=submit]:focus {
	  clear:both;
	  float:right;
	  margin-top:20px;
		font-size:1.5em; border:none; background:#000; color:#fff; padding:2px 10px; text-decoration:none;
		-webkit-appearance:none !important; -webkit-border-radius:0 !important; border-radius:0 !important;
	}
	.mailchimp-signup-subscribe-form-description{
		line-height: 1.25; font-size:2em;
	}

}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) { 
  .page-signup{
	  #node-content, 
	  .mailchimp-newsletter-mergefields {
		background-image: url(../images/line@2x.png);
		background-size:auto 2px;
	  }
  }

}
