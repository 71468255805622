/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */

/**
 * Wireframes.
 */
.with-wireframes #header,
.with-wireframes #main,
.with-wireframes #content,
.with-wireframes #navigation,
.with-wireframes .region-sidebar-first,
.with-wireframes .region-sidebar-second,
.with-wireframes #footer,
.with-wireframes .region-bottom {
  outline: 1px solid #ccc;
}
.lt-ie8 .with-wireframes #header,
.lt-ie8 .with-wireframes #main,
.lt-ie8 .with-wireframes #content,
.lt-ie8 .with-wireframes #navigation,
.lt-ie8 .with-wireframes .region-sidebar-first,
.lt-ie8 .with-wireframes .region-sidebar-second,
.lt-ie8 .with-wireframes #footer,
.lt-ie8 .with-wireframes .region-bottom {
  /* IE6/7 do not support the outline property. */
  border: 1px solid #ccc;
}

/**
 * Accessibility features.
 */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
.element-focusable,
#navigation .block-menu .block__title,
#navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

/* Turns off the element-invisible effect. */
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0;
}
#skip-link a,
#skip-link a:visited {
  display: block;
  width: 100%;
  padding: 2px 0 3px 0;
  text-align: center;
  background-color: #666;
  color: #fff;
}

/**
 * Branding header.
 */


/* Logo image. */
.header__logo-image {
  vertical-align: bottom;
}

/* Wrapper for website name and slogan. */
.header__name-and-slogan {
  float: left;
}

/* The name of the website. */
.header__site-name {
  margin: 0;
  font-size: 2em;
  line-height: 1.5em;
}

/* The link around the name of the website. */
.header__site-link:link,
.header__site-link:visited {
  color: #000;
  text-decoration: none;
}
.header__site-link:hover,
.header__site-link:focus {
  text-decoration: underline;
}

/* The slogan (or tagline) of a website. */
.header__site-slogan {
  margin: 0;
}

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right; /* LTR */
}

/* Wrapper for any blocks placed in the header region. */
.header__region {
  /* Clear the logo. */
  clear: both;
}

/**
 * Navigation bar.
 */
#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */
}
#navigation .block {
  margin-bottom: 0;
}

/* Main menu and secondary menu links and menu block links. */
#navigation .links,
#navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left; /* LTR */
}
#navigation .links li,
#navigation .menu li {
  /* A simple method to get navigation links to appear in one line. */
  float: left; /* LTR */
  padding: 0 10px 0 0; /* LTR */
  list-style-type: none;
  list-style-image: none;
}

/**
 * Breadcrumb navigation.
 */
.breadcrumb ol {
  margin: 0;
  padding: 0;
}
.breadcrumb li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}


/**
 * Messages.
 */
.messages,
.messages--status,
.messages--warning,
.messages--error {
  margin: 1.5em 0;
  padding: 10px 10px 10px 50px; /* LTR */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=');
  *background-image: url('../../images/message-24-ok.png');
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}
.messages--warning {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACuElEQVRIiWP4//8/Ay0xSYqntTpnT252zqeJBf0Njhsykrz/pyd6/e9vcNpGVQv6q2wlm0qc/r0+IPD/3UG+/61l9v9mdrjIUc2C7hqHUzc3S///eZwBjO9tF/vfWe1wjioWTKixVm8otPn38wQT3IKfxxn/t5Va/utpsNSg2ILWcttrNzdJgQ3+dpQRjEHs+9tE/zeXWt+gyILOamuTqlxrsOtPLub+7+emBsSq/88v5wL7oqHQ9H9nmbkF2RbUF1rev7lJEuziuU3i/90ddcB4UZsoJC62ifyvK7R4QJYFrcUGrmUZ5v9hYb9hosh/bzcDMN42VRgeF9W5hv8bi/XdSbagKtfs2c1NEvCIPbaQ/7+/pwkYn17Ki0hR24T/l2eZPCfJgsZ83dCiNOP/yCnn7iau/8G+5mD8aBsHSoqqyNL9X5erHUm0BcVpRm9ubhZHMoTh/4eDzP/DA23+RwTZ/P96hAlF7t5Wof8FyfpvibKgNk8noyDZ4D9quofg1Bjr/1kJlhjiIF+Upmn/r83RzCJoQXaC3qcbm8SwGMLwvybP/H8jMGlik7u7VeB/Zqz2J7wWVGdr1uTG62J1PQgfWST1/+hiCaxyIF8UJqv9r8hQrcVpQVqkzrcbG0WwGvB2H/P/lnx5MAaxsam5vYn3f2KY+jesFpSlqfZnxWjidP2OGWL/g/0swBjExu4Lhv958Ur/i5KU+lEsCA1lYI4JUv95bZ0gTo2Pt3P+z0myBmMQG5e6mxu4/kf4Kf8EmQm3oCRNebKrvSawIGPBqRG9sMOp5hjjfwdrlf/58bKT4RaUpWvtcLZV/39iscD/H0AFP46jYwYiMeP/44u4/9tbKQODSXUH3II9G7v18hI0n8YGKv+IDVT6joxj/BVx4mgcOCde/SnITPRUJAHEGlTCEkQV19TAAN8FC67hZdFXAAAAAElFTkSuQmCC');
  *background-image: url('../../images/message-24-warning.png');
  border-color: #ed5;
}
.messages--error {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACpElEQVR42rWWTUgbQRiGQ0Tx4MWDeFM8eBA9iKAoggiCoCiiIiL4L3oQV1CJB0UEf6iRYFpK7UniTw9VSqL2kvQsVDBpSZrtRo35czVNW3oprRf17exSl4yzu1ikAy9h59vvedhkMrMGAGoxknAk2w8MJ/WosXThiZkZt9jdLeglPjn5ATc3mhJNuNjbK0QbG3ExMICL/n6IfX0gcxB7ekDAELu6IHZ2IlJbi1hLS1BLogmPtbUhMTv7oMSamzUlqnByMxLT0/8STQkDj9TV4ZLj5OysrODl8jIu5Gs68dFR7JG6dWkJ0fFx+TpSX89IDMnwcHU1yKec12Yz3rlc4HkeLwjkXJpPip3U3+7vIx6P4ymph4eG5PlwTQ0lMdytlmBxMWKtrXLeT0zA5XTibvj9fjxfXETkb/3N/Dz2dneVuiTZtliU/rPSUsQ5ziuxZYG03IIlJdKKUPJjdRUAKMmzuTnskB/VYbdTtd9HR4g2NCi9Z2VliDY1BSnBaUEBzsrLqXyzWCiQ9HU5HA4afniIUFWV0hOqqMBpURErOM7NxWlhIZOvCwvA7S3Uxq+DA5AnZ3pO8vJYQSArC8c5Oeqx2Rj4udeLQH6+6v2B7GxW8DkjA0JmJpONwUHY7XZGIAgCzCYTeJUewmIFfqMRfEoKlQ2yJbza2oLWcLvdeDI2hk/3+iQWKzAYkJzNjg5srq9TwJ9OJ76YTNScx+ORJT66X1/grKyEbW2NgfPp6XKd/JMZySrHaQsSU1Oe+0/w3WpVgyu5HBlR6lc+H8gioevDwz6JrWwV5+3txyoSFk5DcOX1MnCyJ4Vwfb1zt1UY9SR8aioDpuppaVpwZbPTl+hHF04dOKzk8XBF8DgJC3/woU/W/EciOtELOWi8DDwp//215Q+p7kiKh2lQSAAAAABJRU5ErkJggg==');
  *background-image: url('../../images/message-24-error.png');
  border-color: #ed541d;
}
.messages__list {
  margin: 0;
}
.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333;
}

/* System status report. */
.ok,
.messages--status {
  background-color: #f8fff0;
  color: #234600;
}
.warning,
.messages--warning {
  background-color: #fffce5;
  color: #840;
}
.error,
.messages--error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/**
 * Tabs.
 */

/* Basic positioning styles shared by primary and secondary tabs. */
.tabs-primary,
.tabs-secondary {
  overflow: hidden;
  *zoom: 1;
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  white-space: nowrap;
}
.tabs-primary__tab,
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  float: left; /* LTR */
}
a.tabs-primary__tab-link,
a.tabs-secondary__tab-link {
  display: block;
  text-decoration: none;
}

/* Primary tabs. */


/* Secondary tabs. */
.tabs-secondary {
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -1.5em;
}
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  margin: 0.75em 3px;
}
a.tabs-secondary__tab-link,
a.tabs-secondary__tab-link.is-active {
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  -ms-border-radius: 0.75em;
  -o-border-radius: 0.75em;
  border-radius: 0.75em;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-shadow: 1px 1px 0 white;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
}
a.tabs-secondary__tab-link:hover,
a.tabs-secondary__tab-link:focus {
  background-color: #dedede;
  border-color: #999;
  color: #333;
}
a.tabs-secondary__tab-link:active,
a.tabs-secondary__tab-link.is-active {
  text-shadow: 1px 1px 0 #333333;
  background-color: #666;
  border-color: #000;
  color: #fff;
}

/**
 * Inline styles.
 */

/* List of links generated by theme_links(). */
.inline {
  display: inline;
  padding: 0;
}
.inline li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0; /* LTR */
}

/* The inline field label used by the Fences module. */
span.field-label {
  padding: 0 1em 0 0; /* LTR */
}

/**
 * "More" links.
 */
.more-link {
  text-align: right; /* LTR */
}
.more-help-link {
  text-align: right; /* LTR */
}
.more-help-link a {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC');
  *background-image: url('../../images/help.png');
  background-position: 0 50%; /* LTR */
  background-repeat: no-repeat;
  padding: 1px 0 1px 20px; /* LTR */
}

/**
 * Pager.
 */

/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

.pager-item,     /* A list item containing a page number in the list of pages. */
.pager-first,    /* The first page's list item. */
.pager-previous, /* The previous page's list item. */
.pager-next,     /* The next page's list item. */
.pager-last,     /* The last page's list item. */
.pager-ellipsis, /* A concatenation of several list items using an ellipsis. */
.pager-current { /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
}
.pager-current {
  font-weight: bold;
}

/**
 * Blocks.
 */

/* Block wrapper. */
.block {
  margin-bottom: 1.5em;
}

/**
 * Marker.
 */

/* The "new" or "updated" marker. */
.new,
.update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
}

/**
 * Unpublished note.
 */

/* The word "Unpublished" displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word;
}
.lt-ie8 .node-unpublished > *,
.lt-ie8 .comment-unpublished > * {
  /* Otherwise these elements will appear below the "Unpublished" text. */
  position: relative;
}

/**
 * Comments.
 */

/* Wrapper for the list of comments and its title. */
.comments {
  margin: 1.5em 0;
}

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea;
}

/* Wrapper for a single comment. */
.comment {
  /* Comment's permalink wrapper. */
}
.comment .permalink {
  text-transform: uppercase;
  font-size: 75%;
}

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: 30px; /* LTR */
}

/**
 * Forms.
 */

/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: 1.5em 0;
}

/* Pack groups of checkboxes and radio buttons closer together. */
.form-checkboxes .form-item,
.form-radios .form-item {
  /* Drupal core uses "0.4em 0". */
  margin: 0;
}

/* Form items in a table. */
tr.odd .form-item,
tr.even .form-item {
  margin: 0;
}

/* Highlight the form elements that caused a form submission error. */
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

/* The descriptive help text (separate from the label). */
.form-item .description {
  font-size: 0.85em;
}
.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 2.4em;
}

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
}

/* Buttons used by contrib modules like Media. */
a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left; /* LTR */
}

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */

/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0;
}

/* The "Log in using OpenID" and "Cancel OpenID login" links. */
.openid-link,
.user-link {
  margin-top: 1.5em;
}
html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px; /* LTR */
}
#user-login ul {
  margin: 1.5em 0;
}

/**
 * Drupal admin tables.
 */
form th {
  text-align: left; /* LTR */
  padding-right: 1em; /* LTR */
  border-bottom: 3px solid #ccc;
}
form tbody {
  border-top: 1px solid #ccc;
}
form table ul {
  margin: 0;
}
tr.even,
tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
}
tr.even {
  background-color: #fff;
}
.lt-ie8 tr.even th,
.lt-ie8 tr.even td,
.lt-ie8 tr.odd th,
.lt-ie8 tr.odd td {
  /* IE doesn't display borders on table rows. */
  border-bottom: 1px solid #ccc;
}

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd;
}

/* Center checkboxes inside table cell. */
td.checkbox,
th.checkbox {
  text-align: center;
}

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */

/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js .collapsible .fieldset-legend {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==');
  *background-image: url('../../images/menu-expanded.png');
  background-position: 5px 65%; /* LTR */
  background-repeat: no-repeat;
  padding-left: 15px; /* LTR */
}
html.js .collapsed .fieldset-legend {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC'); /* LTR */
  *background-image: url('../../images/menu-collapsed.png'); /* LTR */
  background-position: 5px 50%; /* LTR */
}
.fieldset-legend .summary {
  color: #999;
  font-size: 0.9em;
  margin-left: 0.5em;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;
}
.progress .bar {
  background: #ccc;
  border-color: #666;
  margin: 0 0.2em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.progress .filled {
  background-color: #0072b9;
  background-image: url('../../images/progress.gif');
}
